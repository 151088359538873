import { Injectable, OnInit } from '@angular/core';

import { SessionServiceAjs } from '../sessions/session.service.ajs';
import { VehicleModel }      from '../vehicles/models/vehicle';

@Injectable({
  providedIn: 'root'
})
export class TireconnectService {
  apiKey    : string;
  locationId: string;

  dataSelector = '#tcwlw_raw_quote';
  storageKey   = 'tireconnectWidgetData';

  constructor (
    private sessionService: SessionServiceAjs
  ) {}

  getPortalUrl ( vehicle : VehicleModel, id : string, type : 'invoice' | 'event' ) : Promise<string> {
    const settings : any = this.getSettings();

    const data : any = {
      api           : settings.tireconnect_api_key,
      reference_id  : id,
      reference_type: type
    };

    if (vehicle.id) {
      data.location_id = settings.tireconnect_location_id;;

      if (vehicle.vin) {
        data.vin = vehicle.vin;
      }
      else {
        data.state   = vehicle.plate_state;
        data.license = vehicle.plate_number;
      }
    }

    this.sessionService.setKey(this.storageKey, data);

    return Promise.resolve('/tireconnect.html');
  }

  getSettings () : any {
    return this.sessionService.company().tireconnect_settings;
  }
}

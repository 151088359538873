import { NgModule } from '@angular/core';

import { SharedModule }       from '../shared/shared.module';
import { TireconnectService } from './tireconnect.service';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    TireconnectService
  ]
})
export class TireconnectModule {}
